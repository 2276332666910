<template>
  <footer class="mainFooter" :class="{ mobile: isMob, start: isStart }">
    <input type="text" ref="dummykeyboard" style="opacity:0; position: absolute; left: -999px; top: -999px;"/>
    <div class="grid-container">
      <div class="row">
        <div class="col-x12">
          <div class="footerContent">
            <div class="footerLogo">
              <!-- <a @click="handleScroll">
                <img :src="returnLogo"/>
              </a> -->
              <div
                data-aos-offset="50"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <div class="flex align-center justify-center">
                  <span class="flex align-center">
                    <img :src="require('@/assets/images/amex_blue_box.svg')"/>
                  </span>
                  <div
                    class="ml-2 flex align-center amexLogoFade"
                    :class="{ business: user.cardType && user.cardType === 2 }"
                  >
                    <span class="imgFirst">
                      <img
                        :src="require('@/assets/images/tagline_consumer.svg')"
                      />
                    </span>
                    <span class="imgSecond">
                      <img
                        :src="require('@/assets/images/tagline_business.svg')"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="spacer"></div>
            <div
              class="footerMenu"
              :class="{
                mobileSimple:
                  device !== 'sm' ||
                  (!user.card.id &&
                    ($route.name === 'Static' ||
                      $route.fullPath === '/datenschutz')),
              }"
              v-if="
                device !== 'sm' ||
                (!user.card.id &&
                  ($route.name === 'Static' ||
                    $route.fullPath === '/datenschutz'))
              "
            >
              <ul
                :class="{
                  mobileSimple:
                    device !== 'sm' ||
                    (!user.card.id &&
                      ($route.name === 'Static' ||
                        $route.fullPath === '/datenschutz')),
                }"
              >
                <li
                  v-if="
                    device === 'sm' &&
                    user.card.id &&
                    ($route.name !== 'Static' ||
                      $route.fullPath !== '/datenschutz')
                  "
                >
                  <router-link :to="{ name: 'Page', params: { slug: 'faqs' } }"
                  >FAQ
                  </router-link
                  >
                </li>
                <template v-for="(item, i) in navItems.footerNav">
                  <li
                    v-if="
                      ((!item.hidden_on_start && $route.name === 'Start') ||
                        $route.name !== 'Start') &&
                      item.slug !== 'faqs'
                    "
                    :key="i"
                  >
                    <a
                      v-if="item.is_external"
                      v-text="item.title"
                      :href="item.url"
                      target="_blank"
                    ></a>
                    <router-link
                      v-else
                      :to="{ name: 'Page', params: { slug: item.slug } }"
                    >
                      {{ item.title }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
            <div
              class="mobileMenu"
              v-if="
                device === 'sm' &&
                user.card.id &&
                ($route.name !== 'Static' || $route.fullPath !== '/datenschutz')
              "
            >
              <ul>
                <li>
                  <router-link :to="{ name: 'Home' }">
                    <span class="menuIconFooter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 26 22"
                      >
                        <path
                          d="M0.3,11.9L11.4,0.4c0.5-0.5,1.5-0.6,2-0.1l0.1,0.1l11.1,11.5c0.4,0.4,0.5,1.1,0.3,1.6c-0.2,0.5-0.8,0.8-1.3,0.8l-0.1,0l-2.7,0v9.2c0,0.8-0.7,1.4-1.4,1.5l-0.1,0h-4.2c-0.5,0-0.9-0.4-0.9-0.9l0-0.1h0v-4.4h-3.6l0,4.4h0l0,0.1c0,0.5-0.4,0.8-0.8,0.9l-0.1,0H5.6c-0.7,0-1.4-0.7-1.5-1.4l0-0.1v-9.3l-2.7,0c-0.5,0-1.1-0.3-1.4-0.8C-0.1,13-0.1,12.4,0.3,11.9L0.3,11.9L11.4,0.4L0.3,11.9L0.3,11.9z M12.5,1.9L2.2,12.5H5c0.5,0,0.8,0.4,0.9,0.8l0,0.1v9.9h3v-3.6c0-1,0.7-1.7,1.7-1.8l0.1,0h3.6c1,0,1.7,0.7,1.8,1.7l0,0.1v3.6h3v-9.9c0-0.5,0.4-0.8,0.8-0.9l0.1,0h2.8L12.5,1.9L12.5,1.9z"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                    <span class="menuTextFooter">Dashboard</span>
                  </router-link>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ 'router-link-exact-active': searchBar }"
                    @click.prevent="handleSearchBar(true)"
                  >
                    <span class="menuIconFooter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22px"
                        height="22px"
                      >
                        <g>
                          <g>
                            <path
                              d="M21.214,22a.784.784,0,0,1-.556-.229l-6.487-6.489a8.646,8.646,0,1,1,1.111-1.111l6.487,6.488A.785.785,0,0,1,21.214,22ZM8.643,1.571a7.072,7.072,0,0,0-5,12.072,7.072,7.072,0,0,0,10-10A7.025,7.025,0,0,0,8.643,1.571Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span class="menuTextFooter">Suchen</span>
                  </a>
                </li>
                <li>
                  <router-link :to="{ name: 'Page', params: { slug: 'faqs' } }">
                    <span class="menuIconFooter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                      >
                        <g>
                          <g>
                            <path
                              d="M11,22A11,11,0,0,1,3.222,3.222,11,11,0,1,1,18.778,18.778,10.928,10.928,0,0,1,11,22ZM11,1.375A9.625,9.625,0,1,0,20.625,11,9.636,9.636,0,0,0,11,1.375Zm0,14.609a.688.688,0,0,1-.688-.687V8.88a.688.688,0,0,1,1.375,0V15.3A.688.688,0,0,1,11,15.984Zm0-8.593a.8.8,0,1,1,.8-.8A.8.8,0,0,1,11,7.391Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span class="menuTextFooter">FAQ</span>
                  </router-link>
                </li>
                <li v-for="(item, index) in topMenu" :key="index">
                  <a :href="item.link" target="_blank">
                    <span class="menuIconFooter"><img :src="item.icon" width="22" height="22" :alt="item.title"/></span>
                    <span class="menuTextFooter">{{ item.title }}</span>
                  </a>
                </li>
                <!--
                                <li>
                                  <router-link
                                    :to="{ name: 'Page', params: { slug: 'kontakt' } }"
                                  >
                                    <span class="menuIconFooter">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                      >
                                        <g>
                                          <g>
                                            <path
                                              d="M18.071,22a.78.78,0,0,1-.555-.229l-4.484-4.485H1.571A1.573,1.573,0,0,1,0,15.714V1.571A1.573,1.573,0,0,1,1.571,0H20.428A1.573,1.573,0,0,1,22,1.571V15.714a1.573,1.573,0,0,1-1.571,1.571H18.857v3.929a.785.785,0,0,1-.485.726A.771.771,0,0,1,18.071,22ZM1.571,1.571V15.714H13.676l3.609,3.6v-3.6h3.143V1.571Zm10.214,11H5.5A.786.786,0,1,1,5.5,11h6.286a.786.786,0,1,1,0,1.571ZM16.5,9.428H5.5a.786.786,0,1,1,0-1.571h11a.786.786,0,0,1,0,1.571Zm0-3.143H5.5a.786.786,0,1,1,0-1.571h11a.786.786,0,0,1,0,1.571Z"
                                              transform="translate(0 0)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </span>
                                    <span class="menuTextFooter">Kontakt</span>
                                  </router-link>
                                </li>
                -->
                <li>
                  <a href="#" @click.prevent="handleNav(true)">
                    <span class="menuIconFooter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <g fill="#FFF" fill-rule="nonzero">
                            <g>
                              <path
                                d="M337 14c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm0 1.375c-5.308 0-9.625 4.317-9.625 9.625s4.317 9.625 9.625 9.625 9.625-4.317 9.625-9.625-4.317-9.625-9.625-9.625zm0 8.25c.365 0 .715.145.972.403.258.258.403.608.403.972 0 .365-.145.715-.403.973-.257.259-.607.404-.972.404s-.715-.145-.973-.404c-.257-.258-.402-.608-.402-.973 0-.364.145-.714.402-.972.258-.259.608-.404.973-.403zm4.125 0c.365 0 .715.145.972.403.258.258.403.608.403.972 0 .365-.145.715-.403.973-.257.259-.607.404-.972.404s-.715-.145-.973-.404c-.257-.258-.402-.608-.402-.973 0-.364.145-.714.402-.972.258-.259.608-.404.973-.403zm-8.25 0c.365 0 .715.145.972.403.258.258.403.608.403.972 0 .365-.145.715-.403.973-.257.259-.607.404-.972.404s-.715-.145-.973-.404c-.257-.258-.402-.608-.402-.973 0-.364.145-.714.402-.972.258-.259.608-.404.973-.403z"
                                transform="translate(-426 -673) translate(100 659)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span class="menuTextFooter">Mehr</span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <div class="mobileSimple" v-if="$route.path === '/kontakt-seite' && $store.state.ui.device === 'sm'">
              <div>
                <ul>
                  <li><a href="/faqs" class="">Datenschutz</a></li>
                  <li><a href="https://www.americanexpress.com/de/legal/impressum.html" target="_blank">Impressum</a></li>
                  <li><a href="https://www.americanexpress.com/de-de/" target="_blank">Amex.de</a></li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Main Nav -->
    <transition name="slideRight" :duration="{ enter: 900, leave: 800 }">
      <div class="menuHolder" v-if="nav">
        <div class="menuHeader">
          <div>
            <span>Menü</span>
            <button class="iconButton small" @click="handleNav(false)">
              <img :src="icons.close" alt=""/>
            </button>
          </div>
        </div>
        <nav>
          <ul>
            <template v-for="(item, i) in navItems.mainNav">
              <li
                :class="{
                  desktopTabletHidden: !item.visible_on_tablet_and_desktop,
                  animated: nav,
                }"
                v-if="mobile ? item.mobile_visible : true"
                :key="'menuItem' + i"
              >
                <a
                  v-if="item.is_external"
                  :href="item.url"
                  target="_blank"
                  @click="handleNav(false)"
                >
                  <span>
                    <img
                      :src="
                        $store.state.init.init.config.mediaBaseUrl + item.icon
                      "
                    />
                  </span>
                  <span v-text="item.title"></span>
                </a>
                <a
                  v-else-if="item.is_card_switch && device === 'lg'"
                  @click.stop="handleCardSwitch()"
                >
                  <span>
                    <img
                      :src="
                        $store.state.init.init.config.mediaBaseUrl + item.icon
                      "
                    />
                  </span>
                  <span v-text="item.title"></span>
                  <div class="changeCardTooltip">
                    <!-- <div class="tooltipInner" v-if="localStorage.hasOwnProperty('uc_functional')"> -->
                    <div class="tooltipInner" v-if="!cookiesCheck">
                      <p>
                        Dein Fortschritt geht bei einem Kartenwechsel derzeit verloren. Bitte stimme den entsprechenden
                        Cookies zu, um deinen <br/>Fortschritt zu erhalten.
                      </p>
                      <a @click.stop="activateCookieModal()"
                      >Einstellungen anpassen</a
                      >
                    </div>
                    <div class="tooltipInner" v-else>
                      <p>
                        Dein Fortschritt geht bei einem <br/>Kartenwechsel nicht verloren.
                      </p>
                    </div>
                  </div>
                </a>
                <a
                  v-else-if="item.is_card_switch && device !== 'lg'"
                  @click.stop="handleCardSwitchPopUp()"
                >
                  <span>
                    <img
                      :src="
                        $store.state.init.init.config.mediaBaseUrl + item.icon
                      "
                    />
                  </span>
                  <span v-text="item.title"></span>
                </a>
                <router-link
                  v-else-if="item.slug === 'dashboard'"
                  :to="{ name: 'Home' }"
                  @click.native="handleNav(false)"
                >
                  <span>
                    <img
                      :src="
                        $store.state.init.init.config.mediaBaseUrl + item.icon
                      "
                    />
                  </span>
                  <span v-text="item.title"></span>
                </router-link>
                <router-link
                  v-else
                  :to="{ name: 'Page', params: { slug: item.slug } }"
                  @click.native="handleNav(false)"
                >
                  <span>
                    <img
                      :src="
                        $store.state.init.init.config.mediaBaseUrl + item.icon
                      "
                    />
                  </span>
                  <span v-text="item.title"></span>
                </router-link>
              </li>
            </template>
          </ul>
        </nav>
        <div class="selectedCardsWrapper">
          <h3>Meine Karten:</h3>
          <ul>
            <template v-for="(item, index) in user.selectedCards">
              <li
                :key="index"
                class="cardWrapper"
                v-if="parseInt(user.card.id) !== parseInt(item.card.id)"
              >
                <a
                  class="switchToSelectedCard"
                  @click.stop="handleCard(item.card)"
                >
                  <img
                    :src="
                      $store.state.init.init.config.mediaBaseUrl +
                      item.card.image_desktop
                    "
                  />
                  <p v-text="item.card.title"></p>
                </a>
                <span
                  class="clearInput"
                  @click.stop="removeSelectedCard(item.card.id)"
                >
                  <img :src="icons.close" alt=""/>
                </span>
              </li>
            </template>
            <li class="cardWrapper">
              <a class="switchToSelectedCard">
                <img
                  class="noCursor"
                  :src="
                    $store.state.init.init.config.mediaBaseUrl +
                    user.card.image_desktop
                  "
                />
                <p v-text="user.card.title"></p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <!-- End of Main Nav -->
    <!-- overlay -->
    <transition name="fadeIn">
      <div
        class="overlay"
        :class="{ search: searchBar }"
        @click="handleOverlay()"
        v-if="searchBar || nav"
      >
        <span class="closeSearchTouch" v-if="device !== 'lg' && searchBar">
          <img :src="require('@/assets/images/x-icon-white.svg')"/>
        </span>
      </div>
    </transition>

    <!-- end of overlay -->
    <!-- main search -->
    <!--    <transition-->
    <!--      :enter-active-class="-->
    <!--        'animated ' + (device === 'lg' ? ' fadeInRight' : ' fadeInUp')-->
    <!--      "-->
    <!--      :leave-active-class="-->
    <!--        'animated ' + (device === 'lg' ? ' fadeOutRight' : ' fadeOutDown')-->
    <!--      "-->
    <!--    >-->
    <div class="headerSearch" v-if="searchBar" tabindex="-1">
        <span
          @click.stop="handleOverlay()"
          v-if="searchBar && device === 'lg'"
          class="closeSearch"
        >
          <img :src="require('@/assets/images/x-icon-white.svg')"/>
        </span>
      <div class="grid-container">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div class="rowInput">
              <div class="inputBox inputIcon rounded medium left transparent">
                <input
                  type="text"
                  placeholder="Suchbegriff eingeben …"
                  v-model="searchQuery"
                  @keypress.enter="handleSearch(searchQuery)"
                  autofocus
                  ref="searchInput"
                  id="searchInput"
                />
                <button
                  class="icon cursor-pointer"
                  @click.stop="handleSearch(searchQuery)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="21.999"
                    height="22"
                    viewBox="0 0 21.999 22"
                  >
                    <g
                      id="Group_3"
                      data-name="Group 3"
                      transform="translate(0)"
                    >
                      <g id="Group_3-2" data-name="Group 3">
                        <path
                          id="Fill_1"
                          data-name="Fill 1"
                          d="M21.214,22a.784.784,0,0,1-.556-.229l-6.487-6.489a8.646,8.646,0,1,1,1.111-1.111l6.487,6.488A.785.785,0,0,1,21.214,22ZM8.643,1.571a7.072,7.072,0,0,0-5,12.072,7.072,7.072,0,0,0,10-10A7.025,7.025,0,0,0,8.643,1.571Z"
                          transform="translate(0 0)"
                          fill="#000"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
                <span
                  class="clearInput"
                  v-if="searchQuery.length > 0"
                  @click.stop="handleClear"
                >
                    <img :src="icons.close" alt=""/>
                  </span>
                <div
                  class="searchDropdown"
                  :class="{ mobile: device !== 'lg' }"
                  v-if="
                      dropResult &&
                      searchQuery.trim(' ') !== '' &&
                      suggests.length > 0
                    "
                >
                  <ul>
                    <template v-for="(item, i) in suggests">
                      <li :key="'ll' + i" v-if="item">
                        <a @click="handleSearch(item)">
                          <span v-text="item"></span>
                        </a>
                      </li>
                    </template>
                    <!-- <li
                      v-if="
                        searchQuery.trim(' ') !== '' && suggests.length < 1
                      "
                    >
                      <span v-text="'No results'"></span>
                    </li> -->
                  </ul>
                </div>
              </div>
              <button
                class="button rounded"
                @click="handleSearch(searchQuery)"
              >
                <span>Suchen</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    </transition>-->
    <!-- end of main search -->
    <!-- Change card modal -->
    <div
      class="modalContainer changeCardModalContainer"
      v-if="dialogOverlay && device !== 'lg'"
    >
      <div class="modalPopup changeCardModalPopup textBackground">
        <div class="popupHeader text-center">
          <h2>Karte wechseln</h2>
          <!-- <div class="spacer"></div> -->
          <span class="iconRight">
            <button
              class="iconButton small"
              type="button"
              @click="dialogOverlay = false"
            >
              <img :src="icons.close" width="100%"/>
            </button>
          </span>
        </div>
        <div class="popupContent">
          <div class="text-center">
            <p class="popUpText" v-if="!cookiesCheck">
              Dein Fortschritt geht bei einem Kartenwechsel derzeit verloren. Bitte stimme den entsprechenden Cookies
              zu, um deinen Fortschritt zu erhalten.
              <a class="activateCookieModal" @click.stop="activateCookieModal()"
              >Einstellungen anpassen</a
              >
            </p>
            <p class="popUpText cookiesSelected" v-else>
              Dein Fortschritt geht bei einem <br/>Kartenwechsel nicht verloren.
            </p>
          </div>
        </div>
        <div class="popupActions flex justify-center">
          <button class="button rounded block" @click="handleCardSwitch()">
            Weiter
          </button>
        </div>
      </div>
    </div>
    <!-- End of change card modal -->
  </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import userStore from '@/store/modules/user'
import searchStore from '@/store/modules/search'
import uiStore from '@/store/modules/ui'
// import CookiePopup from '@/components/cookie-popup.vue'
import dynamicModule from '@/store/utils/dynamicModule'
import { AuthHttp } from '@/helpers/http-helper'

const name = 'user'

export default {
  data () {
    return {
      cookieModal: false,
      dialogOverlay: false,
      // logo: '',
      toggleMenuMobile: false,
      toggleSearchMobile: false,
      searchQuery: '',
      isMob: false,
      isStart: false,
      icons: {
        close: require('@/assets/images/x-icon-blue.svg')
      },
      searchList: [],
      dropResult: false,
      cookiesCheck: false,
      topMenu: []
    }
  },
  props: ['mobile', 'start'],
  components: {},
  mixins: [
    dynamicModule(name, userStore),
    dynamicModule('search', searchStore),
    dynamicModule('ui', uiStore)
  ],
  computed: {
    ...mapState(name, ['user']),
    ...mapState('search', ['suggests']),
    ...mapState('ui', ['device', 'nav', 'searchBar', 'navItems']),
    filterSearch () {
      return this.suggests
    },
    returnLogo () {
      return require('@/assets/images/logo_tagline_' +
        (this.user.cardType === 1 ? 'consumer' : 'business') +
        '.svg')
    }
  },
  updated () {
    this.funcCookieAccepted()
  },
  watch: {
    nav (val) {
      val
        ? (document.getElementsByTagName('body')[0].style = 'overflow: hidden')
        : (document.getElementsByTagName('body')[0].style = '')
    },
    mobile (val) {
      this.isMob = val
    },
    start (val) {
      this.isStart = val
    },
    searchBar (val) {
      if (!val) {
        this.handleClear()
      } else {
        setTimeout(() => {
          this.$refs.searchInput && this.$refs.searchInput.focus()
        }, 500)
      }
    },
    searchQuery (nv, ov) {
      if (nv.length < 1) {
        this.handleClear()
      } else {
        this.handleSuggets()
      }
    }
  },
  async mounted () {
    this.topMenu = await AuthHttp.get('/header-top').then(resp => {
      return resp.data
    })
  },
  methods: {
    ...mapActions(name, [
      'addUser',
      'addCard',
      'addSwitcher',
      'addSelectedCards',
      'addCardType',
      'addLevel'
    ]),
    ...mapActions('search', ['search', 'findSuggests', 'clearSuggests']),
    ...mapActions('ui', [
      'triggerReload',
      'changeCookieModal',
      'changeNav',
      'changeSearchBar'
    ]),
    handleCardSwitch () {
      this.handleNav(false)
      this.dialogOverlay = false
      let selectedCards = this.user.selectedCards
      // Object.assign(selectedCard, )
      const id = this.user.card.id
      const newCard = {
        [`${id}`]: {
          card: this.user.card,
          level: this.user.currentLevel
        }
      }
      selectedCards = { ...selectedCards, ...newCard }
      this.addSelectedCards(selectedCards)
      this.addSwitcher(true)
      this.addUser(this.user)
      this.$router.push({ name: 'Start' })
    },
    handleCardSwitchPopUp () {
      this.dialogOverlay = true
      this.handleNav(false)
    },
    handleOverlay () {
      this.handleNav(false)
      this.handleSearchBar(false)
      this.dropResult = false
    },
    handleSearch (val) {
      const query = val
      if (query.trim(' ') !== '') {
        this.handleOverlay()
        this.$router.push({ name: 'Search', query: { keyword: query } })
      }
    },
    handleSuggets (val) {
      const query = val || this.searchQuery
      if (query.trim(' ') !== '') {
        this.findSuggests({ query: encodeURIComponent(query) }).then((resp) => {
          this.dropResult = true
          this.searchList = resp
        })
      }
    },
    handleClear () {
      this.searchQuery = ''
      this.clearSuggests()
      this.dropResult = false
    },
    handleScroll () {
      let el = document.querySelector('body')
      el.scrollIntoView({ behavior: 'smooth' })
    },
    removeSelectedCard (id) {
      if (this.user.selectedCards.hasOwnProperty(id)) {
        let selectedCards = this.user.selectedCards

        delete selectedCards[id]
        this.addSelectedCards(selectedCards)

        this.addUser(this.user)
        this.handleOverlay()
      }
    },
    activateCookieModal () {
      this.changeCookieModal(true)
      this.handleNav(false)
      this.dialogOverlay = false
    },
    handleCard (card) {
      if (!this.user.selectedCards.hasOwnProperty(this.user.card.id)) {
        let selectedCards = this.user.selectedCards
        const id = this.user.card.id
        const newCard = {
          [`${id}`]: {
            card: this.user.card,
            level: this.user.currentLevel
          }
        }
        selectedCards = { ...selectedCards, ...newCard }
        this.addSelectedCards(selectedCards)
      }
      this.addCardType(parseInt(card.type))
      this.addCard(card)
      this.addLevel(this.user.selectedCards[card.id]?.level || 1)
      this.addUser(this.user)
      this.handleNav(false)
      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home' })
      } else {
        this.triggerReload(true)
      }
    },
    openInNewTab (url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    handleNav (val) {
      this.changeNav(val)
    },
    handleSearchBar (val) {
      this.changeSearchBar(val)

      if (val) {
        // setTimeout(() => {
        this.$refs.dummykeyboard && this.$refs.dummykeyboard.focus()
        // }, 500)
      }
    },
    funcCookieAccepted () {
      if (localStorage.getItem('uc_functional') === 'true') {
        this.cookiesCheck = true
      }
    }
  }
}
</script>
