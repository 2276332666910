<template>
  <header
    :class="{ notHomeHeader: !home, fixed: isFixed, simpleHeader: device === 'sm' && !user.card.id || ($route.name === 'Static' && $route.fullPath === '/datenschutz') }"
    class="stikyNav">

    <div class="navigationWrapper"
         :class="{
          blueHeader:
            ($route.meta && $route.meta.mainHeader) ||
            ($route.name === 'Home' &&
            $store.state.page.homepage.home &&
            $store.state.page.homepage.home.images.dark)
          }">
      <div class="grid-container">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div class="nav">
              <div class="logo">
                <router-link :to="{ name: 'Home' }">
                  <span
                    v-if="$store.state.ui.device !== 'sm' || (!user.card.id || ($route.name === 'Static' && $route.fullPath === '/datenschutz'))">
                    <svg xmlns="http://www.w3.org/2000/svg" width="112.12" height="30.001" viewBox="0 0 112.12 30.001">
                      <g id="AXP_Logotype_Stacked_DeepBlue_CMYK_PE" transform="translate(0)">
                        <path id="Fill_1" data-name="Fill 1" d="M0,12.6H4.22V0H0Z" transform="translate(64.499)"
                              fill="#006fcf"/>
                        <path id="Fill_2" data-name="Fill 2"
                              d="M3.382,12.6H0V0H7.324c2.746,0,4.521,1.6,4.521,4.068A3.976,3.976,0,0,1,8.464,8.046L12.657,12.6l-4.193,0L4.612,8.19H3.382V12.6Zm0-9.739V5.4H6.926c.937,0,1.519-.483,1.519-1.26,0-.636-.47-1.278-1.519-1.278Z"
                              transform="translate(51.238)" fill="#006fcf"/>
                        <path id="Fill_3" data-name="Fill 3"
                              d="M3.738,6.408V6.174C3.738,4.3,4.9,2.952,7.3,2.952h4.157V0H6.976C2.359,0,0,2.664,0,6.174v.252C0,10.332,2.678,12.6,6.8,12.6H8.075l1.5-2.862h-2.2c-2.239,0-3.638-1.134-3.638-3.33"
                              transform="translate(69.924)" fill="#006fcf"/>
                        <path id="Fill_4" data-name="Fill 4"
                              d="M3.9,12.6H0L5.848,0h4.579l5.848,12.6H12.282l-1.136-2.556H5.015L3.9,12.6ZM8.081,3.06h0L6.226,7.272h3.71L8.081,3.06Z"
                              fill="#006fcf"/>
                        <path id="Fill_5" data-name="Fill 5"
                              d="M8.779,7.938,5.685,0H0V12.6H3.558V3.528L7.136,12.6h3.21l3.577-9.072V12.6h3.558V0H11.854L8.779,7.938"
                              transform="translate(16.878)" fill="#006fcf"/>
                        <path id="Fill_6" data-name="Fill 6"
                              d="M0,12.6H11.453V9.738H3.618V7.722H11.26V4.86H3.618v-2h7.835V0H0V12.6"
                              transform="translate(36.168)" fill="#006fcf"/>
                        <path id="Fill_7" data-name="Fill 7"
                              d="M3.9,12.6H0L5.847,0h4.58l5.848,12.6H12.282l-1.136-2.556H5.015L3.9,12.6ZM8.081,3.06h0L6.226,7.272h3.71L8.081,3.06Z"
                              transform="translate(80.172)" fill="#006fcf"/>
                        <path id="Fill_8" data-name="Fill 8"
                              d="M9.342,7.686,4.232,0H0V12.6H3.336V4.626L8.606,12.6h4.052V0H9.342V7.686"
                              transform="translate(97.653)" fill="#006fcf"/>
                        <path id="Fill_9" data-name="Fill 9"
                              d="M7.87,4.86H4.67A1.058,1.058,0,0,1,3.479,3.852a1.056,1.056,0,0,1,1.191-.99h5.787L11.814,0H4.651C1.693,0,0,1.692,0,3.96,0,6.336,1.637,7.722,4.186,7.722h3.2c.706,0,1.19.4,1.19,1.008s-.484,1.008-1.19,1.008H.409V12.6H7.4c2.922,0,4.652-1.656,4.652-4.086,0-2.3-1.526-3.654-4.186-3.654"
                              transform="translate(87.405 17.4)" fill="#006fcf"/>
                        <path id="Fill_10" data-name="Fill 10"
                              d="M3.381,12.6H0V0H7.324c2.746,0,4.521,1.6,4.521,4.068A3.977,3.977,0,0,1,8.464,8.046L12.657,12.6l-4.193,0L4.612,8.19H3.381V12.6Zm0-9.739V5.4H6.926c.937,0,1.518-.483,1.518-1.26,0-.636-.469-1.278-1.518-1.278Z"
                              transform="translate(59.677 17.4)" fill="#006fcf"/>
                        <path id="Fill_11" data-name="Fill 11"
                              d="M0,12.6H11.453V9.738H3.618V7.722H11.26V4.86H3.618v-2h7.835V0H0V12.6"
                              transform="translate(74.144 17.4)" fill="#006fcf"/>
                        <path id="Fill_12" data-name="Fill 12"
                              d="M3.415,12.6H0V0H7.489c2.774,0,4.567,1.667,4.567,4.248,0,2.541-1.879,4.248-4.676,4.248H3.415v4.1Zm0-9.738V5.634H7.087A1.366,1.366,0,0,0,8.622,4.266a1.381,1.381,0,0,0-1.535-1.4Z"
                              transform="translate(45.812 17.4)" fill="#006fcf"/>
                        <path id="Fill_13" data-name="Fill 13"
                              d="M7.87,4.86H4.67A1.059,1.059,0,0,1,3.479,3.852a1.057,1.057,0,0,1,1.191-.99h5.785L11.813,0H4.651C1.693,0,0,1.692,0,3.96,0,6.336,1.637,7.722,4.186,7.722h3.2c.707,0,1.191.4,1.191,1.008S8.093,9.738,7.386,9.738H.409V12.6h7c2.921,0,4.651-1.656,4.651-4.086,0-2.3-1.525-3.654-4.186-3.654"
                              transform="translate(100.064 17.4)" fill="#006fcf"/>
                        <path id="Fill_14" data-name="Fill 14"
                              d="M0,12.6H11.453V9.738H3.618V7.722H11.26V4.86H3.618v-2h7.835V0H0V12.6"
                              transform="translate(16.878 17.4)" fill="#006fcf"/>
                        <path id="Fill_15" data-name="Fill 15"
                              d="M16.049,0H11.566L8.138,3.8,4.709,0H.113l5.8,6.228L0,12.6H4.483l3.6-4,3.6,4h4.6L10.323,6.156,16.049,0"
                              transform="translate(29.537 17.4)" fill="#006fcf"/>
                      </g>
                    </svg>
                  </span>
                  <span v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="255"
                         height="15.792" viewBox="0 0 255 15.792">
                      <defs>
                        <clipPath id="clip-path">
                          <path id="Clip_2" data-name="Clip 2" d="M0,0H255V15.792H0Z" transform="translate(0 0.196)"
                                fill="none"/>
                        </clipPath>
                      </defs>
                      <g id="AXP_Logotype_SingleLine_Reverse_White_PE" transform="translate(0 -0.196)"
                         clip-path="url(#clip-path)">
                        <path id="Fill_1" data-name="Fill 1" d="M0,15.8H4.233V0H0Z" transform="translate(77.171 0.196)"
                              fill="#fffffe"/>
                        <path id="Fill_3" data-name="Fill 3"
                              d="M4.233,15.805H0V0H9.167A5.989,5.989,0,0,1,13.3,1.417,4.844,4.844,0,0,1,14.827,5.1a4.986,4.986,0,0,1-4.233,4.991l5.25,5.71-5.25,0L5.773,10.273H4.233V15.8Zm0-12.215V6.773H8.67c1.173,0,1.9-.606,1.9-1.58,0-.8-.588-1.6-1.9-1.6Z"
                              transform="translate(60.126 0.196)" fill="#fffffe"/>
                        <path id="Fill_4" data-name="Fill 4"
                              d="M4.233,8.038V7.744A3.7,3.7,0,0,1,8.262,3.7h4.708V0H7.9C2.671,0,0,3.342,0,7.744V8.06C0,12.96,3.033,15.8,7.7,15.8H9.145l1.7-3.59H8.353c-2.535,0-4.12-1.422-4.12-4.177"
                              transform="translate(83.645 0.196)" fill="#fffffe"/>
                        <path id="Fill_5" data-name="Fill 5"
                              d="M4.663,15.8H0L6.995,0h5.478l6.994,15.8H14.692L13.333,12.6H6L4.663,15.8Zm5-11.966L7.447,9.122h4.437L9.666,3.839Z"
                              transform="translate(0 0.196)" fill="#fffffe"/>
                        <path id="Fill_6" data-name="Fill 6"
                              d="M10.277,9.957,6.655,0H0V15.8H4.165V4.425L8.353,15.8h3.758L16.3,4.425V15.8h4.165V0H13.876l-3.6,9.957"
                              transform="translate(20.26 0.196)" fill="#fffffe"/>
                        <path id="Fill_7" data-name="Fill 7"
                              d="M0,15.8H13.4v-3.59H4.233V9.686h8.941V6.1H4.233V3.59H13.4V0H0V15.8"
                              transform="translate(43.959 0.196)" fill="#fffffe"/>
                        <path id="Fill_8" data-name="Fill 8"
                              d="M4.663,15.8H0L6.995,0h5.478l6.994,15.8H14.692L13.333,12.6H6L4.663,15.8Zm5-11.966L7.447,9.122h4.437L9.666,3.839Z"
                              transform="translate(94.759 0.196)" fill="#fffffe"/>
                        <path id="Fill_9" data-name="Fill 9"
                              d="M11.794,9.641,5.342,0H0V15.8H4.21V5.8l6.655,10h5.116V0H11.794V9.641"
                              transform="translate(115.019 0.196)" fill="#fffffe"/>
                        <path id="Fill_10" data-name="Fill 10"
                              d="M9.575,6.1H5.681A1.3,1.3,0,0,1,4.233,4.832,1.3,1.3,0,0,1,5.681,3.59h7.04L14.375,0H5.658C2.06,0,0,2.122,0,4.967c0,2.98,1.992,4.719,5.094,4.719H8.988a1.276,1.276,0,1,1,0,2.529H.5V15.8h8.51c3.554,0,5.66-2.077,5.66-5.125,0-2.89-1.856-4.583-5.094-4.583"
                              transform="translate(224.767 0.196)" fill="#fffffe"/>
                        <path id="Fill_11" data-name="Fill 11"
                              d="M4.234,15.805H0V0H9.166A5.992,5.992,0,0,1,13.3,1.417,4.843,4.843,0,0,1,14.827,5.1a4.987,4.987,0,0,1-4.234,4.991l5.25,5.71-5.25,0L5.771,10.273H4.234V15.8Zm0-12.215V6.773H8.669c1.173,0,1.9-.606,1.9-1.58,0-.739-.5-1.6-1.9-1.6Z"
                              transform="translate(192.544 0.196)" fill="#fffffe"/>
                        <path id="Fill_12" data-name="Fill 12"
                              d="M0,15.8H13.4v-3.59H4.233V9.686h8.942V6.1H4.233V3.59H13.4V0H0V15.8"
                              transform="translate(209.587 0.196)" fill="#fffffe"/>
                        <path id="Fill_13" data-name="Fill 13"
                              d="M4.233,15.8H0V0H9.281a5.834,5.834,0,0,1,4.136,1.479,5.171,5.171,0,0,1,1.522,3.849c0,3.187-2.329,5.329-5.794,5.329H4.233V15.8Zm0-12.214V7.067h4.55a1.7,1.7,0,0,0,1.9-1.716,1.719,1.719,0,0,0-1.9-1.76Z"
                              transform="translate(175.793 0.196)" fill="#fffffe"/>
                        <path id="Fill_14" data-name="Fill 14"
                              d="M9.575,6.1H5.681A1.3,1.3,0,0,1,4.233,4.832,1.3,1.3,0,0,1,5.681,3.59h7.04L14.373,0H5.658C2.06,0,0,2.122,0,4.967c0,2.98,1.992,4.719,5.094,4.719H8.985a1.276,1.276,0,1,1,0,2.529H.5V15.8h8.51c3.554,0,5.66-2.077,5.66-5.125,0-2.89-1.856-4.583-5.094-4.583"
                              transform="translate(240.331 0.196)" fill="#fffffe"/>
                        <path id="Fill_15" data-name="Fill 15"
                              d="M0,15.8H13.4v-3.59H4.233V9.686h8.941V6.1H4.233V3.59H13.4V0H0V15.8"
                              transform="translate(141.725 0.196)" fill="#fffffe"/>
                        <path id="Fill_16" data-name="Fill 16"
                              d="M19.286,0H13.9L9.779,4.764,5.659,0H.136L7.108,7.812,0,15.8H5.387l4.324-5.012L14.035,15.8h5.523L12.4,7.722,19.286,0"
                              transform="translate(155.737 0.196)" fill="#fffffe"/>
                      </g>
                    </svg>
                  </span>
                  <span class="cardPlace" data-aos="fade-up" data-aos-duration="1000" v-if="card">
                    <img :src="card"/>
                  </span>
                </router-link>
              </div>
              <div class="spacer"></div>
              <div class="menuLinks">
                <ul
                  v-if="(!user.card.id  && ( $route.name === 'Page' || $route.name === 'Static' || $route.fullPath === '/datenschutz')) || !essentialCookiesAccepted"
                  class="staticMenu">
                  <li>
                    <router-link :to="{ name: 'Start' }">zum Information Center</router-link>
                  </li>
                </ul>
                <ul v-else>
                  <li @click="handleSearchBar(true)">
                    <span>Suche</span>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.999"
                         height="22" viewBox="0 0 21.999 22">
                      <g id="Group_3" data-name="Group 3" transform="translate(0)">
                        <g id="Group_3-2" data-name="Group 3">
                          <path id="Fill_1" data-name="Fill 1"
                                d="M21.214,22a.784.784,0,0,1-.556-.229l-6.487-6.489a8.646,8.646,0,1,1,1.111-1.111l6.487,6.488A.785.785,0,0,1,21.214,22ZM8.643,1.571a7.072,7.072,0,0,0-5,12.072,7.072,7.072,0,0,0,10-10A7.025,7.025,0,0,0,8.643,1.571Z"
                                transform="translate(0 0)" fill="#fff"/>
                        </g>
                      </g>
                    </svg>
                  </li>
                  <li v-for="(item, index) in topMenu" :key="index"
                      @click="openInNewTab(item.link)">
                    <span>{{ item.title }}</span>
                    <svg width="0" height="0"></svg>
                    <img :src="item.icon" width="25" height="25" :alt="item.title">
                  </li>
                  <li @click="handleNav(true)">
                    <span>Menü</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="25" viewBox="0 0 5 25">
                      <g fill="none" fill-rule="evenodd">
                        <g
                          :fill="($route.meta && $route.meta.mainHeader && !isFixed) || ($route.name === 'Home' && $store.state.page.homepage.home && $store.state.page.homepage.home.images.dark && !isFixed) ? '#006FCF' : '#FFF'">
                          <g>
                            <g>
                              <g transform="translate(-1311 -112) translate(0 74) translate(1259 38) translate(52)">
                                <circle cx="2.5" cy="2.5" r="2.5"/>
                                <circle cx="2.5" cy="12.5" r="2.5"/>
                                <circle cx="2.5" cy="22.5" r="2.5"/>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { AuthHttp } from '@/helpers/http-helper'

import uiStore from '@/store/modules/ui'
import { mapActions, mapState } from 'vuex'
import userStore from '@/store/modules/user'
import searchStore from '@/store/modules/search'
import dynamicModule from '@/store/utils/dynamicModule'

export default {
  data () {
    return {
      cookieModal: false,
      dialogOverlay: false,
      logo: require('@/assets/images/logo_d@2x.png'),
      logo_mobile: require('@/assets/images/logo_m@2x.png'),
      toggleMenu: false,
      toggleSearch: false,
      searchQuery: '',
      essentialCookiesAccepted: false,
      icons: {
        close: require('@/assets/images/x-icon-blue.svg')
      },
      searchList: [],
      dropResult: false,
      topMenu: []
    }
  },
  props: ['home', 'isFixed'],
  mixins: [
    dynamicModule('user', userStore),
    dynamicModule('search', searchStore),
    dynamicModule('ui', uiStore)
  ],
  computed: {
    ...mapState('user', ['user']),
    ...mapState('search', ['results', 'suggests']),
    ...mapState('ui', ['device', 'nav', 'searchBar']),
    filterSearch () {
      return this.suggests
    },
    card () {
      return this.user && this.user.card.image_desktop && this.$store.state.init?.init?.config?.mediaBaseUrl
        ? this.$store.state.init.init.config?.mediaBaseUrl + this.user.card.image_desktop
        : false
    }
  },
  methods: {
    ...mapActions('user', ['addUser', 'addCard', 'addSwitcher', 'addSelectedCards', 'addCardType', 'addLevel']),
    ...mapActions('search', ['search', 'findSuggests', 'clearSuggests']),
    ...mapActions('ui', ['triggerReload', 'changeCookieModal', 'changeNav', 'changeSearchBar']),
    handleNav (val) {
      this.changeNav(val)
    },
    handleSearchBar (val) {
      this.changeSearchBar(val)
    },
    openInNewTab (url) {
      if (url) {
        window.open(url, '_blank')
      }
    }
  },
  async mounted () {
    this.essentialCookiesAccepted = localStorage.getItem('uc_essential') === 'true'
    this.topMenu = await AuthHttp.get('/header-top').then(resp => {
      return resp.data
    })
  }
}
</script>
